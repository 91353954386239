.ResetPassword {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width:100vw;
}

@media only screen and (min-width: 410px) {
    .reset-password-form {
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: flex-start;
        width:380px;
    }
    .reset-password-button {
        width:386px;
        height:40px;
        color:white;
        cursor:pointer;
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #f76a4e;
        font-size:13px;
        margin-top:16px;
    }
    .reset-password-disabled-button {
        width:386px;
        height:40px;
        color:rgb(95, 95, 95);
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #d5d2d2;
        font-size:13px;
        margin-top:16px;
        cursor:not-allowed;
    }
    .reset-password-form input {
        width:380px;
        height:40px;
        border-radius: 3px;
        color:#333333;
        border:1px solid #3333335d;
        margin-bottom:20px;
    }
    .logo {
        width:380px;
    }

}

@media only screen and (max-width: 410px) {
    .reset-password-form {
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: flex-start;
        width:90vw
    }
    .reset-password-button {
        width:90vw;
        height:40px;
        color:white;
        cursor:pointer;
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #f76a4e;
        font-size:13px;
        margin-top:16px;
    }
    .reset-password-disabled-button {
        width:90vw;
        height:40px;
        color:rgb(95, 95, 95);
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #d5d2d2;
        font-size:13px;
        margin-top:16px;
        cursor:not-allowed;
    }
    .reset-password-form input {
        width:calc(90vw - 6px);
        height:40px;
        border-radius: 3px;
        color:#333333;
        border:1px solid #3333335d;
        margin-bottom:20px;
    }
    .logo {
        width:100%;
    }
}

.description {
    font-size:14px;
    color:#333333;
    padding-bottom:10px;
}

.reset-password-button:hover {
    background-color: #FF4822;
}

.reset-password-form label {
    font-size:12px;
    padding-bottom:10px;
    color:#333333;
}

.match {
    position:fixed;
    margin-top:440px;
    font-size:14px;
    color:red;
}
