.Error {
    display:flex;
    justify-content: center;
    flex-direction:column;
    align-items: center;
    height:100vh;
}

.Error h4 {
    margin-top:-60px;
    font-size:20px;
    font-weight:500;
}

.Error button {
    cursor:pointer;
    border-radius: 8px;
    border: none;
    height: 40px;
    padding: 0px 16px;
    background-color: #FF4822;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(33, 39, 44, 0.25);
    font-size:14px;
    margin-top:10px;
}