.Navigation {
    color:#333333;
}

.main-nav {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:0px 8% 0px calc(8% - 30px);
}

.nav-button {
    background-color: transparent;
    border: 0px;
    cursor:pointer;
}

.open-nav {
    display: flex;
    flex-direction: row;
    gap: 10%;
    padding:0px 8% 0px 8%;
    flex-wrap: wrap;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.open-nav.active {
    opacity: 1;
    max-height: 500px; /* Set a value greater than the expected height */
}

.about-nav {
    width:60%;
    min-width:300px;
}

.about-nav h2 {
    margin:18px 0px 6px 0px;
}

.contact-nav h2 {
    margin:18px 0px 6px 0px;
}

.about-nav p {
    margin:6px 0px 18px 0px;
    font-size:16px;
}

.contact-nav p {
    margin:6px 0px 18px 0px;
    text-decoration: underline;
    cursor:pointer;
    font-size:16px;
}
